import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables } from '../constants';
import { DynamicComponentCreateService } from '../gcs.business.services/dynamic-component-create.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class GcsGraphQlCreateNewCaseService {
  stateCountryCode: any;
  blob: Blob;

  constructor(private apollo: Apollo,
    public service: DynamicComponentCreateService,
    public router: Router) { }

  public getCaseTypesDropdownList(): any {
    try {
      let caseTypeQuery: QueryRef<any>;
      caseTypeQuery = this.apollo.watchQuery({
        query: gql`
        query{
          listCaseTypes{
            items {
              caseTypeCode
              caseTypeName
              caseDisplayOrder
            }
          }
        }
      `,
      });
      caseTypeQuery.valueChanges.subscribe((result) => {
        this.service.WritelogToCloudWatch(result)
        sessionStorage.setItem(SessionVariables.getCaseTypes, JSON.stringify(result.data.listCaseTypes.items));
        this.service.getCaseTypes.next(true);
      },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }

  public getPriorities(caseType: string): any {
    try {
      let priorityQuery: QueryRef<any>;
    priorityQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getPriorityListForCreateCase(input:{caseTypeCode:"${caseType}"}){
         priorityListForCreateCase{
          impactCode
          impactDesc
        }
        }
      }
    `,
    });
    priorityQuery.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      sessionStorage.setItem(SessionVariables.getPriorities,
        JSON.stringify(result.data.getPriorityListForCreateCase.priorityListForCreateCase));
      this.service.getPriorities.next(true);
    },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }

  public getAllowedFileExtentions():any {
    console.log('getAllowedFileExtentions');
    
    try {
      let FileExtentionsQuery: QueryRef<any>;
      FileExtentionsQuery = this.apollo.watchQuery({
        query: gql`
        query{
          listAllowedFileUploadExtensionss {
            items {
              Extension
            }
          }
        }
      `,
      });
      FileExtentionsQuery.valueChanges.subscribe((result) => {
        this.service.WritelogToCloudWatch(result)
        // console.log('>---->',result.data.listAllowedFileUploadExtensionss.items);
        
        sessionStorage.setItem(SessionVariables.getFileExtentions, JSON.stringify(result.data.listAllowedFileUploadExtensionss.items));
        this.service.getFileExtentions.next(true);
      },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      console.log('getAllowedFileExtentions-error');
      
      this.service.WritelogToCloudWatch(error)

    }
  }

  public getContacts(partnerCode: string, caseTypeCode: string): any {
    try {
      let contactsQuery: QueryRef<any>;
    contactsQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getContactListForCreateCase(input:{partnerCode:"${partnerCode}"caseTypeCode:"${caseTypeCode}"}){
          contactListForCreateCase{
            phone
            firstName
            lastName
            email
            customerContactNumber
          }
        }
      }
    `,
    });
    contactsQuery.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      sessionStorage.setItem(SessionVariables.getContacts,
        JSON.stringify(result.data.getContactListForCreateCase.contactListForCreateCase));
      this.service.getContacts.next(true);
    },(err)=>{ this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)


    }

  }

  public getProductFamilies(caseTypeCode: string): any {
    try {
      let productFamilyQuery: QueryRef<any>;
    productFamilyQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getProductInfoForCreateCase(input:{caseTypeCode:"${caseTypeCode}"}){
          productBrandList{
            brandName
            brandCode
            brandDesc
          }
        }
      }
    `,
    });
    productFamilyQuery.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      sessionStorage.setItem(SessionVariables.getProductFamilyList,
        JSON.stringify(result.data.getProductInfoForCreateCase.productBrandList));
      this.service.getProductFamilies.next(true);
    },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }

  public getProductFamiliesForParts(caseTypeCode: string, forPartsList: number): any {
    let productFamilyQuery: QueryRef<any>; // ,forPartsList:${forPartsList}
    productFamilyQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getProductInfoForCreateCase(input:{caseTypeCode:"${caseTypeCode}",forPartsList:${forPartsList}}){
          productBrandList{
            brandName
            brandCode
            brandDesc
          }
        }
      }
    `,
    });
    productFamilyQuery.valueChanges.subscribe((result) => {
      sessionStorage.setItem(SessionVariables.getProductFamilyListForParts,
        JSON.stringify(result.data.getProductInfoForCreateCase.productBrandList));
      this.service.getProductFamiliesForParts.next(true);
    });
  }

  public getProductGroups(caseTypeCode: string, brandCode: number): any {
    try {
      let productGroupQuery: QueryRef<any>;
    productGroupQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getProductInfoForCreateCase(input:{caseTypeCode:"${caseTypeCode}",brandCode:${brandCode}}){
          productBrandGroupsList{
           brandGroupName
           brandGroupCode
           brandGroupDesc
         }
        }
      }
    `,
    });
    productGroupQuery.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      sessionStorage.setItem(SessionVariables.getProductGroupList,
        JSON.stringify(result.data.getProductInfoForCreateCase.productBrandGroupsList));
      this.service.getProductGroups.next(true);
    },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)


    }

  }
  public getProduct(caseTypeCode: string, brandGroupCode: number): any {
    try {
      let productQuery: QueryRef<any>;
      productQuery = this.apollo.watchQuery({
        query: gql`
        query{
          getProductInfoForCreateCase(input:{caseTypeCode:"${caseTypeCode}",brandGroupCode:${brandGroupCode}}){
            productBrandProductsList{
              brandProductName
              brandProductCode
              brandProductDesc
              prodTypeCode
           }
          }
        }
      `,
      });
      productQuery.valueChanges.subscribe((result) => {
        this.service.WritelogToCloudWatch(result)
        sessionStorage.setItem(SessionVariables.getProduct,
          JSON.stringify(result.data.getProductInfoForCreateCase.productBrandProductsList));
        this.service.getProduct.next(true);
      }, (err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }
  public getProductVersion(caseTypeCode: string, prodTypeCode: number): any {
    let productVersionQuery: QueryRef<any>;
    productVersionQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getProductInfoForCreateCase(input:{caseTypeCode:"${caseTypeCode}",brandProductCode:${prodTypeCode}}){
          productBrandVersionsList{
            versionCode
            versionName
            versionDesc
            commercialReference
         }
        }
      }
    `,
    });
    productVersionQuery.valueChanges.subscribe((result) => {
      sessionStorage.setItem(SessionVariables.getProductVersion,
        JSON.stringify(result.data.getProductInfoForCreateCase.productBrandVersionsList));
      this.service.getProductVersion.next(true);
    });
  }
  public getRequestInfo(caseTypeCode: string): any {
    let RequestInfoQuery: QueryRef<any>;
    RequestInfoQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getRequestInfoForCreateCase(input:{caseTypeCode:"${caseTypeCode}"}){
          caseAreaList{
            sourceCode
            sourceDesc
          }
          caseReprodList{
            reprodCode
            reprodDesc
          }
        }
      }
    `,
    });
    RequestInfoQuery.valueChanges.subscribe((result) => {
      sessionStorage.setItem(SessionVariables.requestInfoAreaList,
        JSON.stringify(result.data.getRequestInfoForCreateCase.caseAreaList));
      sessionStorage.setItem(SessionVariables.requestInfoReprodList,
        JSON.stringify(result.data.getRequestInfoForCreateCase.caseReprodList));
      this.service.getRequestInfo.next(true);
    });
  }

  // get Request type for MEP case type
  public getRequestType(): any {
    debugger;
    let RequestInfoQuery: QueryRef<any>;
    RequestInfoQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getRequestType(id:"GCS"){
          id
          requestTypeList
        }
      }
    `,
    });
    RequestInfoQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const getRequestType = resultData && resultData.getRequestType;
      const requestArrayValues = getRequestType.requestTypeList || [];
      const makeRequestTypeArray = [];
      requestArrayValues.forEach(element => {
        const obj = {
          requestTypeName: element,
          requestTypeValue: element
        };
        makeRequestTypeArray.push(obj);
      });
      sessionStorage.setItem(SessionVariables.mepRequestTypeValues, JSON.stringify(makeRequestTypeArray));
      this.service.getRequestTypeValues.next(true);
    });
  }


  public getContractTypes(): any {
    debugger;

    var company_code =sessionStorage.getItem(SessionVariables.selectedCompanyCode)

    //var customer_code = this.service.getIds("customer_code")


    let ContractQuery: QueryRef<any>;
    ContractQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getContracts(input: {current_only: 1, customer_code: ${company_code}}) {
    ContractDesc
    contractCode
  }
      }
    `,
    });

    ContractQuery.valueChanges.subscribe((result) => {

      debugger;
    var contractTypes = [
      // { contractTypeName: 'BILLABLE', contractTypeValue: '11030003052' },
      // { contractTypeName: 'COST INCLUSIVE', contractTypeValue: '11030003053' },
      // { contractTypeName: 'FLEX CREDITS I/A', contractTypeValue: '11030003054' },
      // { contractTypeName: 'FLEX CREDITS TRICONEX', contractTypeValue: '11030003070' }

    ]




      if (result && result.data && result.data.getContracts.length>0)
      contractTypes =result.data.getContracts.map(({ ContractDesc, contractCode }) => { return { contractTypeName: ContractDesc, contractTypeValue: contractCode } })

      sessionStorage.setItem(SessionVariables.mepContractTypeValues, JSON.stringify(contractTypes));

      this.service.getContractTypeValues.next(true);


    },(err)=>{
      this.service.WritelogToCloudWatch("getContractTypes",err)

    })

  }

  // MEP Parts Dropdown
  public getParts(input: any, iaEnabled: number, tricEnabled: number): void {
    let RequestInfoQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    RequestInfoQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getParts(input:{
        JWT: "${sessionToken}",
        screenId: "${screenId}",
        ipAddress: "${ip}",
        ptext: "${input}",
        bIAPT: ${iaEnabled},
        bTRIC: ${tricEnabled}
        }){
          mepParts{
            EquipmentCode
            EquipmentDesc
          }
        }
      }
    `,
    });


    RequestInfoQuery.valueChanges.subscribe((result) => {
      if (input == sessionStorage.getItem(SessionVariables.partsInput)) {
        const resultData = result && result.data;
        const getParts = resultData && resultData.getParts;
        const partArrayValues = getParts.mepParts || [];
        sessionStorage.setItem(SessionVariables.mepPartValues, JSON.stringify(partArrayValues));
        this.service.getPartValues.next(partArrayValues);
      }
    });

  }

  // MEP Failure Type Dropdown
  public getFailureType(): void {
    let RequestInfoQuery: QueryRef<any>;
    RequestInfoQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getFailureType{
          failureTypeList{
            failureTypeCode
            failureTypeDesc
            sourceCode
            statusCode
            clientCode
          }
        }
      }
    `,
    });
    RequestInfoQuery.valueChanges.subscribe((result) => {
      debugger
      const resultData = result && result.data;
      const getFailureType = resultData && resultData.getFailureType;
      const failureArrayValues = getFailureType.failureTypeList || [];

      sessionStorage.setItem(SessionVariables.mepFailureTypeValues, JSON.stringify(failureArrayValues));
      this.service.getFailureTypeValues.next(true);
    });
  }

  // shipTo//
  public getShipTo(partnerCode: string): any {
    debugger;
    const getShipQL = JSON.parse(sessionStorage.getItem(SessionVariables.getCompanies));
    let priorityQuery: QueryRef<any>;
    priorityQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getBillToAndShipToDetails(input:{
          partnerNumber:"${partnerCode}",
          partnerType:"SH"
        }){
          billToAndShipToDetails{
            partnerFunction
            partnerDesc
            customerNumber
            companyName
            address1
            address2
            city
            state
            country
            zip
            partnerNumber
            partnerCounter
          }
        }
      }
    `,
    });
    priorityQuery.valueChanges.subscribe((result) => {
      sessionStorage.setItem(SessionVariables.getShiptToValues,
        JSON.stringify(result.data.getBillToAndShipToDetails.billToAndShipToDetails));
      this.service.getShipTo.next(true);
    });
  }

  // shipTo//

  // billTo//
  public getBillTo(partnerCode: string): any {
    debugger;
    const getShipQL = JSON.parse(sessionStorage.getItem(SessionVariables.getCompanies));
    let priorityQueryValue: QueryRef<any>;
    priorityQueryValue = this.apollo.watchQuery({
      query: gql`
    query{
      getBillToAndShipToDetails(input:{
        partnerNumber:"${partnerCode}",
        partnerType:"BP"
      }){
        billToAndShipToDetails{
          partnerFunction
          partnerDesc
          customerNumber
          companyName
          address1
          address2
          city
          state
          country
          zip
          partnerNumber
          partnerCounter
        }
      }
    }
  `,
    });
    priorityQueryValue.valueChanges.subscribe((result) => {
      sessionStorage.setItem(SessionVariables.getBillToValues,
        JSON.stringify(result.data.getBillToAndShipToDetails.billToAndShipToDetails));
      this.service.getBillTo.next(true);
    });
  }

  // Download File//
  public getDownloadTo(uid: string, title: any, filetype: any): any {
    const loggedUser = this.service.getIds();
    // const getDownloadQL = JSON.parse(sessionStorage.getItem(SessionVariables.getCompanies));
    let downloadQueryValue: QueryRef<any>;
    downloadQueryValue = this.apollo.watchQuery({
      query: gql`
     query{
      bfoDownloadFile(input:{
        accessToken:"${loggedUser.accessToken}",
        fileId:"${uid}"
      }){
        fileContent
      }
    }
   `,
    });
    downloadQueryValue.valueChanges.subscribe((result) => {
      const downloadBlob = result && result.data && result.data.bfoDownloadFile && result.data.bfoDownloadFile.fileContent;
      const downloadURL = window.URL.createObjectURL(new Blob([downloadBlob], { type: `${filetype}` }));
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = title + '.' + filetype;
      link.click();
      this.service.getDownloadTo.next(true);
    });
  }

  // Get Field Service Type Lists
  public getFieldServiceType(): any {
    let fieldServiceType: QueryRef<any>;
    fieldServiceType = this.apollo.watchQuery({
      query: gql`
      query{
        getFieldServiceTypes{
          fieldServiceTypes{
            fieldServiceId
            description
          }
        }
      }
    `,
    });
    fieldServiceType.valueChanges.subscribe((result) => {
      const resultData = result && result.data && result.data.getFieldServiceTypes;
      sessionStorage.setItem(SessionVariables.getOssFieldServiceTypes,
        JSON.stringify(resultData.fieldServiceTypes));
      this.service.getFieldServiceType.next(true);
    });
  }

  // Get Field Service Type Lists
  public getFieldServiceOss(): any {
    let fieldServiceSupportList: QueryRef<any>;
    fieldServiceSupportList = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getOSSSupportList(id: "GCS"){
          id
          services
        }
      } `,
    });
    fieldServiceSupportList.valueChanges.subscribe((result) => {
      const resultData = result && result.data && result.data.getOSSSupportList;
      const ossSupport = []
      resultData.services.forEach(element => {
        const obj = {
          description: element,
          fieldServiceId: element
        };
        ossSupport.push(obj);
      });
      sessionStorage.setItem(SessionVariables.getOSSSupportList,
        JSON.stringify(ossSupport));
      this.service.getFieldServiceOss.next(true);
    });
  }


  // Get Field Training Type Lists
  public getFieldServiceOssTraining(): any {
    let fieldServiceSupportList: QueryRef<any>;
    fieldServiceSupportList = this.apollo.watchQuery({
      query: gql`
      query{
        getOSSTrainingList(id:"GCS"){
          id
          trainings
        }
      }`,
    });
    fieldServiceSupportList.valueChanges.subscribe((result) => {
      const resultData = result && result.data && result.data.getOSSTrainingList;
      const ossTraining = [];
      resultData.trainings.forEach(element => {
        const obj = {
          description: element,
          fieldServiceId: element
        };
        ossTraining.push(obj);
      });
      sessionStorage.setItem(SessionVariables.getOSSTrainingList,
        JSON.stringify(ossTraining));
      this.service.getFieldServiceOssTraining.next(true);
    });
  }
  // Get Field Location Type Lists
  public getFieldServiceOssLocation(): any {
    let fieldServiceLocationList: QueryRef<any>;
    fieldServiceLocationList = this.apollo.watchQuery({
      query: gql`
      query{
        getOSSTrainingLocationList(id:"GCS"){
          id
          locations
        }
      } `,
    });
    fieldServiceLocationList.valueChanges.subscribe((result) => {
      const resultData = result && result.data && result.data.getOSSTrainingLocationList;
      const ossLocation = [];
      resultData.locations.forEach(element => {
        const obj = {
          description: element,
          fieldServiceId: element
        };
        ossLocation.push(obj);
      });
      sessionStorage.setItem(SessionVariables.getOSSTrainingLocationList,
        JSON.stringify(ossLocation));
      this.service.getFieldServiceOssLocation.next(true);
    });
  }

  // Get Field Course Type Lists
  public getFieldServiceOssCourse(): any {
    let fieldServiceCourseList: QueryRef<any>;
    fieldServiceCourseList = this.apollo.watchQuery({
      query: gql`
      query{
        getOSSCourseList(id:"GCS"){
          id
          courses
        }
      }`,
    });
    fieldServiceCourseList.valueChanges.subscribe((result) => {
      const resultData = result && result.data && result.data.getOSSCourseList;
      const ossCourse = [];
      resultData.courses.forEach(element => {
        const obj = {
          description: element,
          fieldServiceId: element
        }
        ossCourse.push(obj);
      });
      sessionStorage.setItem(SessionVariables.getOSSCourseList,
        JSON.stringify(ossCourse));
      this.service.getFieldServiceOssCourse.next(true);
    });
  }
  // Get Field Service Type Lists
  public getFieldEngineeringServiceOss(): any {
    let fieldEngineeringServiceList: QueryRef<any>;
    fieldEngineeringServiceList = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getOSSServiceList(id: "GCS"){
          id
          services
        }
      } `,
    });
    fieldEngineeringServiceList.valueChanges.subscribe((result) => {
      const resultData = result && result.data && result.data.getOSSServiceList;
      const ossService = []
      resultData.services.forEach(element => {
        const obj = {
          description: element,
          fieldServiceId: element
        };
        ossService.push(obj);
      });
      sessionStorage.setItem(SessionVariables.getFieldEngineeringServiceOssList,
        JSON.stringify(ossService));
      this.service.getFieldEngineeringServiceOss.next(true);
    });
  }

}
