export enum SessionVariables {
  isUserLoggedIn = 'isUserLoggedIn',
  loggedInUserDetails = 'loggedInUserDetails',
  code = 'code',
  showFooter = 'showFooter',
  IdmsInfo = 'IdmsInfo',
  caseList = 'caseList',
  caseTypes = 'caseTypes',
  caseFilters = 'caseFilters',
  caseStatus = 'caseStatus',
  companyDropDownList = 'companyDropDownList',
  companyData = 'companyData',
  companyTotalCount = 'companyTotalCount',
  notificationsAndAlerts = 'notificationsAndAlerts',
  allNotificationsAndAlerts = 'allNotificationsAndAlerts',
  overViewUserInfo = 'overViewUserInfo',
  overViewInfo = 'overViewInfo',
  headerNavChips = 'headerNavChips',
  rerrangeViewData = 'rerrangeViewData',
  defaultFlag = 'defaultFlag',
  regionalGcsInfo = 'regionalGcsInfo',
  gcsUsers = 'gcsUsers',
  remoteServices = 'remoteServices',
  gcsSites = 'gcsSites',
  quickLinks = 'quickLinks',
  allSubMenus = 'allSubMenus',
  getProductFamily = 'getProductFamily',
  getProductFamilyListForParts = "getProductFamilyListForParts",
  getRearrangeView = 'getRearrangeView',
  companyHomeData = 'companyHomeData',
  myOpenCases = 'myOpenCases',
  allOpenCases = 'allOpenCases',
  customerRegistrationLocation = 'customerRegistrationLocation',
  customerRegistrationEmail = 'customerRegistrationEmail',
  customerRegistrationStateLocation = 'customerRegistrationStateLocation',
  customerRegistrationProductFamily = 'customerRegistrationProductFamily',
  sessionCache = 'sessionCache',
  showSpinner = 'showSpinner',
  getCaseTypes = 'getCaseTypes',
  getPriorities = 'getPriorities',
  getCompanies = 'getCompanies',
  getSites = 'getSites',
  getContacts = 'getContacts',
  genSubmitCaseData = 'genSubmitCaseData',
  getProductFamilyList = 'getProductFamilyList',
  getProductGroupList = 'getProductGroupList',
  getProduct = 'getProduct',
  getProductVersion = 'getProductVersion',
  requestInfoAreaList = 'requestInfoAreaList',
  requestInfoReprodList = 'requestInfoReprodList',
  createCaseFirstForm = 'createCaseFirstForm',
  selectedCaseType = 'selectedCaseType',
  selectedPriority = 'selectedPriority',
  selectedCompany = 'selectedCompany',
  selectedCompanyCode ='selectedCompanyCode',
  selectedSite = 'selectedSite',
  selectedProductFamily = 'selectedProductFamily',
  selectedProductGroup = 'selectedProductGroup',
  selectedProducts = 'selectedProducts',
  selectedProductVersion = 'selectedProductVersion',
  selectedArea = 'selectedArea',
  selectedReprod = 'selectedReprod',
  createCaseGenForm = 'createCaseGenForm',
  genSummaryCaseData = 'genSummaryCaseData',
  ossSummaryCaseData = 'ossSummaryCaseData',
  mepProductRequestInfo = 'mepProductRequestInfo',
  mepRequestTypeValues = 'mepRequestTypeValues',
  mepContractTypeValues = 'mepContractTypeValues',
  mepPartValues = 'mepPartValues',
  getShiptToValues = 'getShiptToValues',
  getBillToValues = 'getBillToValues',
  PERsubjectProductForm = 'PERsubjectProductForm',
  TECsubjectProductForm = 'TECsubjectProductForm',
  mepFailureTypeValues = 'mepFailureTypeValues',
  mepPartsGridData = 'mepPartsGridData',
  getTECPERData = 'getTECPERData',
  getCaseTypeSubscription = 'getCaseTypeSubscription',
  genAddCaseData = 'genAddCaseData',
  changeEmail = 'changeEmail',
  recentlySelectedCompanyName = 'recentlySelectedCompanyName',
  homeCaseNumber = 'homeCaseNumber',
  homeCaseId = 'homeCaseId',
  homeCaseType = 'homeCaseType',
  homeUseCode = 'homeUseCode',
  mepShippingBillingDetails = 'mepShippingBillingDetails',
  mepContaminationInfoDetails = 'mepContaminationInfoDetails',
  mepProductDataConfirm = 'mepProductDataConfirm',
  mepShippingBillingConfirm = 'mepShippingBillingConfirm',
  TECdescription = 'TECdescription',
  PERdescription = 'PERdescription',
  PERsuggestedSolution = 'PERsuggestedSolution',
  PERanticipatedBenefits = 'PERanticipatedBenefits',
  previousSitesList = 'previousSitesList',
  videosLinks = 'videosLinks',
  getTecPerComments = 'getTecPerComments',
  TecOrPerSubmitCaseData = 'TecOrPerSubmitCaseData',
  selectedSiteId = 'selectedSiteId',
  selectedCommercialReference = 'selectedCommercialReference',
  getFileDataTo = 'getFileDataTo',
  tecSummaryCaseData = 'tecSummaryCaseData',
  perSummaryCaseData = 'perSummaryCaseData',
  getTECPERCommentData = 'getTECPERCommentData',
  TECcomment = 'TECcomment',
  PERcomment = 'PERcomment',
  sessionToken = 'sessionToken',
  iss = 'iss',
  ossProductRequestInfo = 'ossProductRequestInfo',
  getOssFieldServiceTypes = 'getOssFieldServiceTypes',
  mepSubmitCaseData = 'mepSubmitCaseData',
  mepSummaryCaseData = 'mepSummaryCaseData',
  mepSummaryOrderData = 'mepSummaryOrderData',
  ip = 'ip',
  updatedUserName = 'updatedUserName',
  ossPreventiveMaintenanceInfo = 'ossPreventiveMaintenanceInfo',
  ossSystemUpgradeServicesInfo = 'ossSystemUpgradeServicesInfo',
  ossSUSEstimatedDurationOfVisit = 'ossSUSEstimatedDurationOfVisit',
  ossSUSInvensysOrderEquip = 'ossSUSInvensysOrderEquip',
  ossSUSCustomerOrderEquip = 'ossSUSCustomerOrderEquip',
  getOSSSupportList = 'getOSSSupportList',
  getOSSTrainingList = 'getOSSTrainingList',
  getOSSTrainingLocationList = 'getOSSTrainingLocationList',
  getOSSCourseList = 'getOSSCourseList',
  ossProductDataConfirm = 'ossProductDataConfirm',
  viewAllCases = 'viewAllCases',
  ossPartsGridData = 'ossPartsGridData',
  selectedRequestType = 'selectedRequestType',
  getFieldEngineeringServiceOssList = 'getFieldEngineeringServiceOssList',
  cookieEnabled = 'cookieEnabled',
  ossPartsRequired = 'ossPartsRequired',
  ossSubmitCaseData = 'ossSubmitCaseData',
  companyFilter = 'companyFilter',
  bellNotificationsAndAlerts = 'bellNotificationsAndAlerts',
  updatedNotificationData = 'updatedNotificationData',
  getbannerMessage = 'getbannerMessage',
  caseListColumns = 'caseListColumns',
  caseListTempColumns = 'caseListTempColumns',
  bfoLogs = 'bfoLogs',
  getTECPERDat = 'getTECPERDat',
  errorOverview = 'errorOverview',
  errorCaseList = 'errorCaseList',
  selectedContactDetails = 'selectedContactDetails',
  allGcsAccountDetails = "allGcsAccountDetails",
  overviewFilter = "overviewFilter",
  postFileData = "postFileData",
  showBanner = "showBanner",
  isLoggedIn = "isLoggedIn",
  totalCount = "totalCount",
  totalCountBfo = "totalCountBfo",
  caseNumber = "caseNumber",
  registrationResponseMsg = "registrationResponseMsg",
  cookiePolicyAccepted = "cookiePolicyAccepted",
  statsCookieAccepted = "statsCookieAccepted",
  registrationSuccessful = "registrationSuccessful",
  selectedCountryCode = "selectedCountryCode",
  countryPhoneExt = "countryPhoneExt",
  selectedCountryDesc = "selectedCountryDesc",
  telephonenumber = "telephonenumber",
  location = "location",
  partsSearchInputValue = "partsSearchInputValue",
  partsSearchInputCategory = "partsSearchInputCategory",
  partsSearchInputLCGroup = "partsSearchInputLCGroup",
  partsSearchInputStatus = "partsSearchInputStatus",
  partsSearchInputInModels = "partsSearchInputInModels",
  partsSearchInputInParts = "partsSearchInputInParts",
  partsSearchInputUpdates = "partsSearchInputUpdates",
  partsSearchInputSuperseded = "partsSearchInputSuperseded",
  lastname = "lastname",
  confirmbusinessemail = "confirmbusinessemail",
  businessemail = "businessemail",
  firstname = "firstname",
  zipcode = "zipcode",
  state = "state",
  city = "city",
  address = "address",
  companyname = "companyname",
  recaptcha = "recaptcha",
  product = "product",
  isTotal = "isTotal",
  compName = "compName",
  callingCaseListAppComp = "callingCaseListAppComp",
  isZeroCasesCmMyOpenCases = "isZeroCasesCmMyOpenCases",
  isZeroCasesHomeMyOpenCases = "isZeroCasesHomeMyOpenCases",
  SupportLinks ="SupportLinks",
  loginTime = "loginTime",
  siteId = "siteId",
  customerSiteInfo = "customerSiteInfo",
  siteEquipmentDetails = "siteEquipmentDetails",
  partSearchDetails = "partSearchDetails",
  savDetails = "savDetails",
  remoteServicesEmails = "remoteServicesEmails",
  siteDetails = "siteDetails",
  remoteServicesTopStations = "remoteServicesTopStations",
  remoteServicesTopMessages = "remoteServicesTopMessages",
  remoteServicesEventMessages = "remoteServicesEventMessages",
  RwsMsgId = "RwsMsgId",
  messageInfo = "messageInfo",
  refreshedOnce = "refreshedOnce",
  mepNewFeatureEnable ="mepNewFeatureEnable",
  selectedContract ="selectedContract",
  industryTypes = "industryTypes",
  productVersions = "productVersions",
  appTypes = "appTypes",
  DCSList = "DCSList",
  partsInput = "partsInput",
  siteCreated = "siteCreated",
  getFileExtentions ="getFileExtentions"
}

export enum LocaStorageVariables {
  isRedirectFromIDMS = 'isRedirectFromIDMS'
}

export enum Environments {
  default = 'default',
  sit = 'sit',
  uat = 'uat',
  qa = 'qa',
  preprod = 'preprod',
  prod = 'prod'
}

export enum AppVariables {
  showfooter = 'showfooter',
  active = 'active',
  tabNumber = 'tabNumber'
}

export enum ErrorCodes {
  ErrorCode1000 = 'Database Connection time out',
  ErrorCode1001 = 'Database Record update error',
  ErrorCode1100 = 'Email timeout',
  ErrorCode1101 = 'Email unverified',
  ErrorCode1200 = 'IDMS timeout',
  ErrorCode1300 = 'BFO timeout',
  ErrorCode1400 = 'SAP timeout',
  ErrorCode1500 = 'Apeigee timeout',
  ErrorCode1600 = 'Amplify Angular error',
  ErrorCode1700 = 'Node Lambda error',
  ErrorCode1800 = 'SES Email timeout',
  JWT_EXPIRED = 'JWT_EXPIRED',
  REFRESH_TOKEN_EXPIRED = 'REFRESH_EXPIRED',
  Execution_TimeOut = 'Execution timed out.'
}
export enum TimedOutMessage {
  timedOutMessage = 'Execution Timed Out Please Try Again Later',
  titleMessage = 'Execution Error',
  logType = 'ERROR_TIMEOUT',
  logMessage = 'Execution Timed out Error '
}

export enum SuccessCodes {
  SuccessCode1000 = 'General Success Code'
}

export enum LoadingSpinnerTimeOut {
  MainLoginPage = 10,
  CompanyOverView = 5,
  CaseList = 5
}
export enum TollFreeNumbers {
  EMEA = '+31-3554-84125',
  Cairo = '+2-02-27559750',
  FoxboroMA = '+1 508-549-2424',
  FoxboroUS = '+1 866-746-6477',
  chennaiNumber = '+91 444 244 0000',
  shangaiNumber = '+86 400 810 1315',
  internationalNumber = '+86 21 3718 0086',
  foxboroSCADA = '+1 508-549-2424',
  foxboroUS = '+1 866-746-6477',
  geoSCADA = '+1 613-591-1943',
  geoSCADAus = '+1 888-226-6876',
  processIntrumentationNumber = '+1 508-549-2424',
  processIntrumentationUSNumber = '+1 866-746-6477'
}

export enum CustomerTypeParameters {
  LimitedCutomerUserGroup = 1,
  LimitedCutomerLevelCode = 4,
  parentAccessEnabledValue = 4,
  nonLimitedCustomerUserGroup = 2,
  nonLimitedCustomerLevelCode = 2,
  employeeCode = 4
}

export const GCSPages = {
  0: {
    name: 'home',
    route: 'gcsweb/home',
    queryParameter: ''
  },
  1: {
    name: 'sitesPage',
    route: '/gcsweb/my-company/gcs-sites',
    queryParameter: 'gcs-sites'
  },
  2: {
    name: 'caseDetails',
    route: 'gcsweb/case-management/case-summary',
    queryParameters: ['caseNumber', 'caseType']
  },
  3: {
    name: 'profileSettings',
    route: '/gcsweb/homepage-avatar-settings',
    queryParameter: 'tab',
    queryParameterValues: {
      accountInformationTab: 1,
      settingsAndPreferencesTab: 2
    }
  },
  4: {
    name: 'caseManagmentCasesHome',
    route: 'gcsweb/case-management/cases-home'
  },
  5: {
    name: 'remoteServices',
    route: 'gcsweb/my-company/remote-services'
  }
  ,
  6: {
    name: 'logout',
    route: 'gcsweb/gcs-login/logout' // logout component not exist
  }
};

export enum routing {
  gcsweb = 'gcsweb',
}

//these are params for redirection to legacyGCS
export enum RedirectionParams {
  localCompanyCodeParamkey = 'LCC',
  siteIdParamKey = 'SiteID',
  siteGuidParamKey = 'SC',
}

export const screenIdsForTelemetry = {
  'home-overview': {
    screenId: 'gcsweb2020-home-overview'
  },
  'case-mangement-cases-home': {
    screenId: 'gcsweb2020-case-mangement-cases-home'
  },
  'case-management-create-case': {
    screenId: 'gcsweb2020-case-mangement-create-case'
  },
  'profile-account-information': {
    screenId: 'gcsweb2020-profile-account-information'
  },
  'case-summary': {
    screenId: 'gcsweb2020-case-summary'
  },
  'my-company-company-home': {
    screenId: 'gcsweb2020-my-company-company-home'
  }
}

export const seEmailIds = ['se.com', 'schneider-electric.com', 'non.schneider-electric.com', 'non.se.com'];

export const privacyPolicyUrl = 'https://secureidentity.schneider-electric.com/identity/VFP_IDMSPrivacySelector?app=mySchneiderWeb&lang=en';
// GW-1822- Fixing this updateEmail link to the correct environmental link
// already in environmental files as changeEmail
// export const updateEmailUrl =   'https://uatbfo21-secommunities.cs190.force.com/identity/changeemailmobile?app=PASupportWebsite';
export enum exploreMoreLinks {
  FoxboroDCS = 'https://www.se.com/ww/en/work/products/industrial-automation-control/foxboro-dcs',
  ProcessInstrumentation = 'https://www.se.com/ww/en/brands/foxboro/foxboro-measurement-instrumentation-products.jsp',
  FoxboroSCADA = 'https://www.se.com/ww/en/product-range/63678-foxboro-scada-rtus/?parent-subcategory-id=6040&filter=business-1-industrial-automation-and-control&subNodeId=12366859713en_WW',
  Triconex = 'https://www.se.com/ww/en/work/products/industrial-automation-control/triconex-safety-systems/',
  BuyAutomation = 'https://buyautomation.se.com/showroom/default.asp',
  CyberSecurity = 'https://www.se.com/ww/en/work/solutions/cybersecurity/industrial-cybersecurity.jsp',
  LearningServices = 'https://palearningservices.se.com/',
  EcoStruxurePlant = 'https://www.se.com/ww/en/work/solutions/industrial-process-automation/'
}

export enum caseTypes {
  Hot = 'Hot',
  Open = 'Open',
  Total = 'Total',
  GEN = 'GEN',
  OSS = 'OSS',
  MEP = 'MEP',
  PER = 'PER',
  TEC = 'TEC',
  RWS = 'RWS'
}

export const TelInputPlaceholder = 'Select location first to enable this field';
export enum gcsWebAccess {
  gcsWebAccessUser = 1,
  gcsNotWebAccessUser = 0
}
export enum levelCodes {
  newUser = 0,
  badEmail = 10,
  rejectedCode = 200,
  rejectedCode1 = 300,
  disabledCode = 400
}


export const mepNewFeatureEnableGroup="171"//171
export const cfaLinkEnabled ="174"  //GW-2446
 
