import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
import { query } from '@angular/animations';
@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlPartSearchServiceSecure {

  constructor(
    private apollo: Apollo,
    private service: DynamicComponentCreateService,
    private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure) { }

  public getPartSearch():void {
    const _searchValue = sessionStorage.getItem(SessionVariables.partsSearchInputValue)
        const _categoryValue = sessionStorage.getItem(SessionVariables.partsSearchInputCategory)
        const _lifeCycleGroupValue = sessionStorage.getItem(SessionVariables.partsSearchInputLCGroup)
        const _statusValue = sessionStorage.getItem(SessionVariables.partsSearchInputStatus)
        const _includeModelsFlag = sessionStorage.getItem(SessionVariables.partsSearchInputInModels)
        const _includePartsFlag = sessionStorage.getItem(SessionVariables.partsSearchInputInParts)
        const _onlyUpdatesFlag = sessionStorage.getItem(SessionVariables.partsSearchInputUpdates)
        const _onlySupersededFlag = sessionStorage.getItem(SessionVariables.partsSearchInputSuperseded)

        let partSearchQuery: QueryRef<any>;
        let ip = sessionStorage.getItem(SessionVariables.ip);
        ip = ip ? ip : '';
        const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
        let screenId = '';
        partSearchQuery = this.apollo.watchQuery({
        query: gql`query{
          getPartSearch(input:{
            searchText: "${_searchValue}",
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}",
            category:"${_categoryValue}",
            lifeCycleGroup:"${_lifeCycleGroupValue}",
            includeModels: ${_includeModelsFlag},
            includeParts: ${_includePartsFlag},
            onlyUpdates: ${_onlyUpdatesFlag},
            onlySuperseded: ${_onlySupersededFlag},
            status: "${_statusValue}"
            }){
              allPartCategory {
                category_CD
                category_desc
              }
              allPartLifeCycle {
                Category_cd
                Product_line_Cd
                TRIC
                Short_text
              }
              allPartSearch {
                Part_Number
                Status_Cd
                Configurable
                Software
                PSS_URL
                DateIntroduced
                DateAvailable
                DateMature
                DateLifetime
                DateUnrepair
                Short_Text
                Long_Text
                Category_Cd
                EquipmentGroupID
                Life_Cycle_Desc
                EquipmentLifeCycleColor
                Superceded_Part_number
              }
              allPartStatus {
                status_cd
                status_desc
              }

            }
        }
        `,
        });
        
        partSearchQuery.valueChanges.subscribe((result) => {
        const resultData = result && result.data;
        const partSearchDetails = resultData && resultData.getPartSearch;
        sessionStorage.setItem(SessionVariables.partSearchDetails, JSON.stringify(partSearchDetails));
        this.service.getPartSearchDetails.next(true);

        //! Testing
          // console.log(partSearchDetails);
          // console.log(JSON.stringify(partSearchDetails));
          // console.log('--------- SessionVariables -------------');
          // console.log(sessionStorage.getItem(SessionVariables.partSearchDetails));
          // console.log(this.service.getPartSearchDetails.getValue());
        //! End Testing
        
        },
        (err) => {
          console.log('error');
          
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
                } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getPartSearch();
                }
            })
        }
        });
  }
}
